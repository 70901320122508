import { useMutation } from "@tanstack/react-query";

import { userApiHandler } from "@/services/apiHandlers/user";

const useLogoutMutation = () => {
  const { mutateAsync: logoutUser } = useMutation({
    mutationFn: () => userApiHandler.logoutUser(),
  });

  return {
    logoutUser,
  };
};

export default useLogoutMutation;
