import {
  SET_CURRENT_LOCALE,
  SET_TRANSLATIONS,
} from "@/providers/translationsProvider/actionTypes";
import type {
  ITranslationsAction,
  ITranslationsState,
} from "@/providers/translationsProvider/types";

const translationsReducer = (
  state: ITranslationsState,
  action: ITranslationsAction,
): ITranslationsState => {
  switch (action.type) {
    case SET_TRANSLATIONS:
      return { ...state, translations: action.payload.translations };
    case SET_CURRENT_LOCALE:
      return { ...state, currentLocale: action.payload.currentLocale };
    default:
      return state;
  }
};

export default translationsReducer;
