import type {
  PartialRecord,
  PureNullable,
  QueryModel,
} from "@/common/globalTypes/utillityTypes/common";

export enum HTTP_METHODS_ENUM {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export enum HEADERS_ENUM {
  contentType = "Content-Type",
  timeZone = "TimeZone",
  authorization = "Authorization",
  xCurrentLocale = "x-current-locale",
  xWorkspaceName = "x-workspace-name",
  xProjectId = "x-project-id",
  accept = "accept",
}

export enum CONTENT_TYPE_ENUM {
  formData = "",
  applicationJson = "application/json",
}

export enum BackendResponseModelTypes {
  WithData = "WithData",
}

export interface IRequestParameters {
  url: string;
  method: HTTP_METHODS_ENUM;
  data?: PureNullable<unknown>;
  headerOptions?: PartialRecord<HEADERS_ENUM, string>;
  hideErrorMessage?: boolean;
  cache?: RequestCache;
  next?: { revalidate?: number; tags?: string[] };
  signal?: AbortSignal;
  responseModel?: keyof typeof BackendResponseModelTypes;
}

export interface IHttpMethodsDefaultParameters {
  url: string;
  headerOptions?: PartialRecord<HEADERS_ENUM, string>;
  hideErrorMessage?: boolean;
  responseModel?: keyof typeof BackendResponseModelTypes;
}

export interface IGetProps extends IHttpMethodsDefaultParameters {
  queryParam?: QueryModel;
  cache?: RequestCache;
  next?: { revalidate?: number; tags?: string[] };
}

export interface IPostProps extends IHttpMethodsDefaultParameters {
  data?: PureNullable<unknown>;
}

export interface IPatchProps extends IHttpMethodsDefaultParameters {
  data?: PureNullable<unknown>;
}

export interface IDeleteProps extends IHttpMethodsDefaultParameters {}

export enum ENVIRONMENTS_KEYS {
  sso = "sso",
  appStoreBackend = "appStoreBackend",
}
