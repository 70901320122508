"use client";

import type { PropsWithChildren } from "react";
import Link from "next/link";
import type { UrlObject } from "url";

import type { IAppLink } from "@/components/atoms/AppLink/types";
import { useAuthUser } from "@/hooks/common/useAuthUser";
import { useTranslations } from "@/hooks/common/useTranslations";

import { APP_STORE_SUPPORTED_LANGUAGES_ENUM } from "@/common/globalTypes/enums/common";

const AppLink = ({ href, children, ...props }: PropsWithChildren<IAppLink>) => {
  const { currentLocale } = useTranslations();
  const { isCustomDomain, workspaceConfig } = useAuthUser();

  const rewriteHref = (
    originalHref: string | UrlObject,
  ): string | UrlObject => {
    const isDefaultLang =
      currentLocale === APP_STORE_SUPPORTED_LANGUAGES_ENUM.en;

    if (isCustomDomain) {
      return isDefaultLang ? originalHref : `/${currentLocale}${originalHref}`;
    }

    return isDefaultLang
      ? `/${workspaceConfig?.workspace}/${originalHref}`
      : `/${workspaceConfig?.workspace}/${currentLocale}${originalHref}`;
  };

  return (
    <Link href={rewriteHref(href)} {...props}>
      {children}
    </Link>
  );
};

export default AppLink;
