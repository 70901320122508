import { usePathname } from "next/navigation";

import { useAuthContext } from "@/hooks/common/useAuthContext";

import { EnvConfig } from "@/common/constants/envConfig";

const useAppPathname = () => {
  const {
    state: { workspaceConfig },
  } = useAuthContext();

  const pathname = usePathname();

  return workspaceConfig?.partnerDefaultOrCustomDomain ===
    EnvConfig.generalDomain
    ? pathname.replace(new RegExp(`/${workspaceConfig?.workspace}(/|$)`), "/")
    : pathname;
};

export default useAppPathname;
