import { cva } from "class-variance-authority";

export const paragraphVariants = cva(`scroll-m-20`, {
  variants: {
    size: {
      small: "text-base leading-6",
      extraSmall: "text-sm leading-5",
      base: "text-xl leading-6",
      large: "text-2xl leading-7",
      extraLarge: "text-3xl leading-8",
    },
    fontWeight: {
      regular: "font-normal",
      medium: "font-medium",
      bold: "font-bold",
    },
    type: {
      secondary: "text-muted-foreground",
    },
  },
  defaultVariants: {
    size: "base",
    fontWeight: "regular",
  },
});
