export enum APP_STORE_SUPPORTED_LANGUAGES_ENUM {
  en = "en",
  ru = "ru",
}

export enum ICONS_VARIABLE_NAMES {
  primary = "var(--primary-color-icon)",
  secondary = "var(--secondary-color-icon)",
}

export enum SCREEN_SIZES {
  sm = "(max-width: 576px)",
  md = "(max-width: 768px)",
  lg = "(max-width: 992px)",
  xl = "(max-width: 1200px)",
  xxl = "(max-width: 1600px)",
}

export enum GALLERY_TYPE {
  image = "image",
  video = "video",
}

export enum APP_TYPE_ENUM {
  NATIVE = "NATIVE",
  CUSTOM = "CUSTOM",
}

export enum TAG_STATUS_TYPES_ENUM {
  LIVE = "LIVE",
  NOT_INSTALLED = "NOT_INSTALLED",
  FREE = "FREE",
  NOT_FREE = "NOT_FREE",
  MOST_HELPFUL_REVIEW = "MOST_HELPFUL_REVIEW",
  NOT_HELPFUL_REVIEW = "NOT_HELPFUL_REVIEW",
}

export enum HelpfulOrUnhelpfulReviewEnum {
  helpful = "helpful",
  unhelpful = "unhelpful",
}
