import {
  LOG_OUT_USER,
  SET_PROJECT,
  SET_USER,
} from "@/providers/authProvider/actionTypes";
import type { IAuthAction, IAuthState } from "@/providers/authProvider/types";

const authReducer = (state: IAuthState, action: IAuthAction): IAuthState => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload.user };
    case SET_PROJECT:
      return { ...state, projectId: action.payload.projectId };
    case LOG_OUT_USER:
      return { ...state, user: null, projectId: null };
    default:
      return state;
  }
};

export default authReducer;
