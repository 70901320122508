import { redirect } from "next/navigation";

import { useLogoutMutation } from "@/hooks/apis/mutations/useLogoutMutation";
import useAppPathname from "@/hooks/common/useAppPathname/useAppPathname";
import { useAuthContext } from "@/hooks/common/useAuthContext";

import {
  deleteAllCookiesApi,
  returnUrlQueryParam,
  targetName,
} from "@/common/constants/authorization";
import { EnvConfig } from "@/common/constants/envConfig";
import type { TargetNameWithSsoType } from "@/common/globalTypes/types/common";

const useAuthUser = () => {
  const {
    state: { user, projectId, workspaceConfig },
  } = useAuthContext();

  const pathname = useAppPathname();

  const { logoutUser } = useLogoutMutation();

  const isLogged = Boolean(user?.id);

  const isCustomDomain =
    workspaceConfig?.partnerDefaultOrCustomDomain !== EnvConfig.generalDomain;

  const redirectHandlerWithSso = (
    target: TargetNameWithSsoType,
    returnUrl: string,
  ) => {
    const encodedReturnUrl = encodeURIComponent(returnUrl);

    // eslint-disable-next-line no-return-assign
    return (window.location.href = `${workspaceConfig?.endpoints?.sso}/callback?${targetName}=${target}&${returnUrlQueryParam}=${encodedReturnUrl}`);
  };

  const logout = async () => {
    await logoutUser();

    return redirect(
      `${workspaceConfig?.endpoints?.merchantAppStore}/${deleteAllCookiesApi}`,
    );
  };

  const signUpHandler = () => {
    window.open(`${workspaceConfig?.endpoints?.merchantSignUp}`, "_self");
  };

  const logIn = async (
    customPath?: string,
    callBackRedirectHandler?: () => void,
  ) => {
    if (!isLogged) {
      const comeBackTo = customPath || pathname;

      redirectHandlerWithSso("app-store", comeBackTo);

      return null;
    }

    return callBackRedirectHandler ? callBackRedirectHandler() : false;
  };

  return {
    user,
    projectId,
    workspaceConfig,
    redirectHandlerWithSso,
    isCustomDomain,
    logIn,
    signUpHandler,
    logout,
    isLogged,
  };
};

export default useAuthUser;
