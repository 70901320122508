import isNotServerSide from "@/helpers/isNotServerSide";

interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
}

class CookieHandler {
  static getCookie(name: string): string | undefined {
    if (isNotServerSide) {
      const cookies = document.cookie.split(";");

      for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.trim().split("=");

        // eslint-disable-next-line max-depth
        if (cookieName === name) {
          return cookieValue;
        }
      }
    }

    return undefined;
  }

  // eslint-disable-next-line complexity
  static setCookie(
    name: string,
    value: string,
    options: CookieOptions = {},
  ): void {
    let cookie = `${name}=${value}`;

    if (options.expires) {
      const date = new Date(options.expires);
      cookie += `;expires=${date.toUTCString()}`;
    }

    if (options.path) {
      cookie += `;path=${options.path}`;
    }

    if (options.domain) {
      cookie += `;domain=${options.domain}`;
    }

    if (options.secure) {
      cookie += ";secure";
    }

    if (options.httpOnly) {
      cookie += ";httpOnly";
    }

    document.cookie = cookie;
  }

  static deleteCookie(name: string): void {
    CookieHandler.setCookie(name, "", { expires: new Date(0) });
  }
}

export default CookieHandler;
