export const unauthorizedStatus = 401;
export const authorizationTokenKey = "token";
export const BEARER = "Bearer";

export const returnUrlQueryParam = "returnUrl";

export const targetName = "target";

export const target = "app-store";

export const nonce = "nonce";

export const AppPackage = "application_package";
export const ReferenceId = "reference-id";
export const deleteAllCookiesApi = "api/unset-cookie";
