import { forwardRef } from "react";

import { paragraphVariants } from "@/components/atoms/Paragraph/constants";
import type { IParagraphProps } from "@/components/atoms/Paragraph/types";
import cn from "@/helpers/cn";

const Paragraph = forwardRef<HTMLParagraphElement, IParagraphProps>(
  ({ size, fontWeight, type, children, className, ...props }, ref) => {
    return (
      <p
        className={cn(paragraphVariants({ size, fontWeight, type, className }))}
        ref={ref}
        {...props}
      >
        {children}
      </p>
    );
  },
);

Paragraph.displayName = "Paragraph";

export default Paragraph;
