import { cva } from "class-variance-authority";

export const headingVariants = cva(`scroll-m-20`, {
  variants: {
    size: {
      h1: "text-5xl leading-10",
      h2: "text-4xl leading-10",
      h3: "text-3xl leading-9",
      h4: "text-2xl leading-7",
    },
    fontWeight: {
      regular: "font-normal",
      medium: "font-medium",
      bold: "font-bold",
    },
  },
  defaultVariants: {
    size: "h1",
    fontWeight: "regular",
  },
});
