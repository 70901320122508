import { notFound, redirect } from "next/navigation";

import buildHeaders from "@/helpers/buildHeaders";
import isNotServerSide from "@/helpers/isNotServerSide";

import { getWorkspaceEnvs } from "@/app/actions";
import {
  deleteAllCookiesApi,
  unauthorizedStatus,
} from "@/common/constants/authorization";
import type { PureNullable } from "@/common/globalTypes/utillityTypes/common";
import { defaultTypeOfCache } from "@/services/http/constants";
import type {
  ENVIRONMENTS_KEYS,
  IRequestParameters,
} from "@/services/http/types";
import {
  BackendResponseModelTypes,
  CONTENT_TYPE_ENUM,
  HTTP_METHODS_ENUM,
} from "@/services/http/types";

class BaseFetcher {
  private readonly environmentKey: ENVIRONMENTS_KEYS;

  constructor(environmentKey: ENVIRONMENTS_KEYS) {
    this.environmentKey = environmentKey;
  }

  // eslint-disable-next-line complexity
  async request<T>({
    url,
    method,
    data = null,
    headerOptions = {},
    hideErrorMessage,
    cache = defaultTypeOfCache,
    next,
    signal,
    responseModel,
  }: IRequestParameters): Promise<T> {
    const headers = await buildHeaders(headerOptions);
    const environments = await getWorkspaceEnvs();

    const body: PureNullable<BodyInit> = data ? JSON.stringify(data) : null;

    const fetchOptions: RequestInit = {
      method,
      headers: {
        accept: CONTENT_TYPE_ENUM.applicationJson,
        ...headers,
      },
      body,
      signal,
      ...(next ? { next } : { cache }),
    };

    const response = await fetch(
      `${environments[this.environmentKey]}/${url}`,
      fetchOptions,
    );

    if (response.status === unauthorizedStatus && isNotServerSide) {
      return redirect(
        `${environments.merchantAppStore}/${deleteAllCookiesApi}`,
      );
    }

    if (response.status === 404) {
      return notFound();
    }

    if (!response.ok) {
      const errorRes = await response.json();
      const message =
        errorRes?.message ||
        errorRes?.error?.description ||
        errorRes?.type?.description ||
        errorRes?.data?.error ||
        "An error occurred";

      throw {
        message,
        hideErrorMessage,
        statusCode: response.status,
      };
    }

    if (method === HTTP_METHODS_ENUM.DELETE || response.status === 204) {
      return {} as T;
    }

    const result = await response.json();

    return responseModel === BackendResponseModelTypes.WithData
      ? result.data
      : result;
  }
}

export default BaseFetcher;
