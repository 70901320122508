import { useEffect } from "react";
import { useHoory } from "@hoory/react";

import { useAuthUser } from "@/hooks/common/useAuthUser";

import { EnvConfig } from "@/common/constants/envConfig";

const HooryInitializer = () => {
  const { user } = useAuthUser();

  useHoory(EnvConfig.hooryWebsiteToken, {
    env: "PROD",
  });

  useEffect(() => {
    window.addEventListener("hoory:ready", () => {
      if (user && user?.email) {
        window.$hoory.setUser(user.email, {
          email: user?.email || undefined,
          name: user?.fullName || undefined,
          avatar_url: user?.avatarUrl || undefined,
          identifier_hash: user?.identifierHash || undefined,
        });
      }
    });
  }, [user]);

  return null;
};

export default HooryInitializer;
