import { ENV_CONFIG_ENUM } from "@/common/globalTypes/enums/http";

export const EnvConfig: Record<ENV_CONFIG_ENUM, string> = {
  [ENV_CONFIG_ENUM.canonicalUrl]: (() => {
    if (!process.env.NEXT_PUBLIC_UCRAFT_APP_STORE_CANONICAL) {
      throw new Error(
        "NEXT_PUBLIC_UCRAFT_APP_STORE_CANONICAL env not provided",
      );
    }

    return process.env.NEXT_PUBLIC_UCRAFT_APP_STORE_CANONICAL;
  })(),
  [ENV_CONFIG_ENUM.generalDomain]: (() => {
    if (!process.env.NEXT_PUBLIC_PLATFORM_NATIVE_DOMAIN) {
      throw new Error("NEXT_PUBLIC_PLATFORM_NATIVE_DOMAIN env not provided");
    }

    return process.env.NEXT_PUBLIC_PLATFORM_NATIVE_DOMAIN;
  })(),
  [ENV_CONFIG_ENUM.protocol]: (() => {
    if (!process.env.NEXT_PUBLIC_PROTOCOL) {
      throw new Error("NEXT_PUBLIC_PROTOCOL env not provided");
    }

    return process.env.NEXT_PUBLIC_PROTOCOL;
  })(),
  [ENV_CONFIG_ENUM.hooryWebsiteToken]: (() => {
    if (!process.env.NEXT_PUBLIC_HOORY_APP_WEBSITE_TOKEN) {
      throw new Error("NEXT_PUBLIC_HOORY_APP_WEBSITE_TOKEN env not provided");
    }

    return process.env.NEXT_PUBLIC_HOORY_APP_WEBSITE_TOKEN;
  })(),
};
