import { BEARER } from "@/common/constants/authorization";
import type { PureNullable } from "@/common/globalTypes/utillityTypes/common";
import { BaseApiHandler } from "@/services/apiHandlers/baseApiHandler";
import type {
  IAccessTokenApiResponse,
  IUserApiResponse,
} from "@/services/apiHandlers/user/types";
import { BackendResponseModelTypes } from "@/services/http/types";

class UserApiHandler extends BaseApiHandler {
  constructor() {
    super();
  }

  async getAuthUser(token?: string): Promise<PureNullable<IUserApiResponse>> {
    if (token) {
      const currentUser =
        await this.request.accountsFetcher.get<IUserApiResponse>({
          url: "api/user",
          cache: "no-cache",
          headerOptions: {
            Authorization: `${BEARER} ${token}`,
          },
          responseModel: BackendResponseModelTypes.WithData,
        });

      return {
        id: currentUser.id,
        fullName: currentUser.fullName,
        email: currentUser.email,
        identifierHash: currentUser.identifierHash,
        avatarUrl: currentUser.avatarUrl,
      };
    }

    return null;
  }

  async getTokenByNonce(nonce: string): Promise<IAccessTokenApiResponse> {
    return this.request.accountsFetcher.get<IAccessTokenApiResponse>({
      url: "api/access-token",
      queryParam: {
        nonce,
      },
    });
  }

  async logoutUser(): Promise<unknown> {
    return this.request.accountsFetcher.post({
      url: "api/logout",
      hideErrorMessage: true,
    });
  }
}

const userApiHandler = new UserApiHandler();

export default userApiHandler;
