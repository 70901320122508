import { forwardRef } from "react";
import { Slot } from "@radix-ui/react-slot";

import { buttonVariants } from "@/components/atoms/Button/constants";
import type { IButtonProps } from "@/components/atoms/Button/types";
import cn from "@/helpers/cn";

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = "Button";

export default Button;
