import { useCallback, useContext } from "react";

import { TranslationsContext } from "@/providers/translationsProvider";

const useTranslations = (namespace?: string) => {
  const context = useContext(TranslationsContext);
  if (!context)
    throw new Error("useTranslations must be used within TranslationProvider");

  const {
    state: { translations, currentLocale },
  } = context;

  const t = useCallback(
    (key: string, values?: Record<string, string | number>) => {
      if (!key) return key;

      const keys = key.split(".");
      let translation: any = namespace ? translations[namespace] : translations; // eslint-disable-line

      for (const part of keys) {
        translation = translation?.[part];
        if (translation === undefined) return key; // If not found, return the key itself
      }

      if (typeof translation === "string" && values) {
        return translation.replace(/\{(\w+)\}/g, (match, p1) => {
          const value = values[p1];

          return value !== undefined ? String(value) : match;
        });
      }

      return typeof translation === "object" ? key : (translation ?? key);
    },
    [translations, namespace],
  );

  return {
    t,
    currentLocale,
  };
};

export default useTranslations;
