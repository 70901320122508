import { HTTP_SERVICE_REQUEST_ENUM } from "@/common/globalTypes/enums/http";
import { HttpService } from "@/services/http";
import { ENVIRONMENTS_KEYS } from "@/services/http/types";

class BaseApiHandler {
  public readonly request: Record<HTTP_SERVICE_REQUEST_ENUM, HttpService>;

  constructor() {
    this.request = {
      [HTTP_SERVICE_REQUEST_ENUM.accountsFetcher]: new HttpService(
        ENVIRONMENTS_KEYS.sso,
      ),
      [HTTP_SERVICE_REQUEST_ENUM.applicationsFetcher]: new HttpService(
        ENVIRONMENTS_KEYS.appStoreBackend,
      ),
    };
  }
}

export default BaseApiHandler;
