"use client";

import { createContext } from "react";

import { APP_STORE_SUPPORTED_LANGUAGES_ENUM } from "@/common/globalTypes/enums/common";
import type { ITranslationsContext } from "@/providers/translationsProvider/types";

const TranslationsContext = createContext<ITranslationsContext>({
  state: {
    translations: {},
    currentLocale: APP_STORE_SUPPORTED_LANGUAGES_ENUM.en,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

export default TranslationsContext;
