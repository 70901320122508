import CookieHandler from "@/helpers/cookieHandler";
import isNotServerSide from "@/helpers/isNotServerSide";

import {
  buildHeaderForTheServerSideCalls,
  getWorkspaceConfigs,
} from "@/app/actions";
import {
  authorizationTokenKey,
  BEARER,
} from "@/common/constants/authorization";
import {
  localeKeyCookie,
  queryParamRedirectedFromDashboard,
} from "@/common/constants/common";
import { APP_STORE_SUPPORTED_LANGUAGES_ENUM } from "@/common/globalTypes/enums/common";
import type { PartialRecord } from "@/common/globalTypes/utillityTypes/common";
import { HEADERS_ENUM } from "@/services/http/types";

const buildHeaders = async (
  options: PartialRecord<HEADERS_ENUM, string>,
): Promise<HeadersInit> => {
  const { name } = await getWorkspaceConfigs();

  options[HEADERS_ENUM.xWorkspaceName] = name;

  if (isNotServerSide) {
    options[HEADERS_ENUM.xCurrentLocale] =
      CookieHandler.getCookie(localeKeyCookie) ||
      APP_STORE_SUPPORTED_LANGUAGES_ENUM.en;
    const projectId = CookieHandler.getCookie(
      queryParamRedirectedFromDashboard,
    );

    const authorizationToken = CookieHandler.getCookie(authorizationTokenKey);

    if (authorizationToken) {
      options[HEADERS_ENUM.authorization] = `${BEARER} ${authorizationToken}`;
    }

    if (projectId) {
      options[HEADERS_ENUM.xProjectId] = projectId;
    }
  } else {
    const addHeadersForTheServerSide = await buildHeaderForTheServerSideCalls();
    options[HEADERS_ENUM.xCurrentLocale] =
      APP_STORE_SUPPORTED_LANGUAGES_ENUM.en;

    if (addHeadersForTheServerSide.authorizationToken) {
      options[HEADERS_ENUM.authorization] =
        `${BEARER} ${addHeadersForTheServerSide.authorizationToken}`;
    }

    if (addHeadersForTheServerSide.projectId) {
      options[HEADERS_ENUM.xProjectId] = addHeadersForTheServerSide.projectId;
    }
  }

  return options;
};

export default buildHeaders;
