import type {
  KeyValuePair,
  QueryModel,
} from "@/common/globalTypes/utillityTypes/common";

const createQueryParamForApiHandlers = (params?: QueryModel): string => {
  if (!params) {
    return "";
  }

  const buildQueryString = (
    obj: KeyValuePair<string | number>,
    parentKey?: string,
  ): string => {
    return Object.keys(obj)
      .map((key) => {
        const nestedKey = parentKey ? `${parentKey}[${key}]` : key;
        const value = obj[key];

        if (typeof value === "object" && value !== null) {
          return buildQueryString(value, nestedKey);
        }

        return `${encodeURIComponent(nestedKey)}=${encodeURIComponent(value)}`;
      })
      .join("&");
  };

  const queryParams = buildQueryString(params);

  return queryParams ? `?${queryParams}` : "";
};

export default createQueryParamForApiHandlers;
