"use client";

import type { PropsWithChildren } from "react";
import { useReducer } from "react";

import { HooryInitializer } from "@/components/molecules/HooryInitializer";
import { NotFoundPage } from "@/components/pages/NotFound";

import { AuthContext } from "@/providers/authProvider";
import authReducer from "@/providers/authProvider/reducer";
import type { IAuthProviderProps } from "@/providers/authProvider/types";

const AuthProvider = ({
  children,
  user,
  projectId,
  workspaceConfig,
}: PropsWithChildren<IAuthProviderProps>) => {
  const [state, dispatch] = useReducer(authReducer, {
    user,
    projectId,
    workspaceConfig,
  });

  const contextValue = { state, dispatch };

  if (!workspaceConfig?.hasAppStore) {
    return <NotFoundPage showBackBtn={false} />;
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
      {workspaceConfig.isCore && <HooryInitializer />}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
