import createQueryParamForApiHandlers from "@/helpers/createQueryParamForApiHandlers";

import type {
  ENVIRONMENTS_KEYS,
  IDeleteProps,
  IGetProps,
  IPatchProps,
  IPostProps,
} from "./types";

import BaseFetcher from "@/services/http/baseFetcher";
import { defaultTypeOfCache } from "@/services/http/constants";
import {
  CONTENT_TYPE_ENUM,
  HEADERS_ENUM,
  HTTP_METHODS_ENUM,
} from "@/services/http/types";

class HttpService extends BaseFetcher {
  constructor(environmentKey: ENVIRONMENTS_KEYS) {
    super(environmentKey);
  }

  get<T>({
    url,
    queryParam,
    headerOptions = {},
    cache = defaultTypeOfCache,
    next,
    hideErrorMessage = false,
    responseModel,
  }: IGetProps) {
    return this.request<T>({
      url: `${url}${createQueryParamForApiHandlers(queryParam)}`,
      method: HTTP_METHODS_ENUM.GET,
      responseModel,
      headerOptions: {
        ...headerOptions,
        [HEADERS_ENUM.contentType]: CONTENT_TYPE_ENUM.applicationJson,
      },
      cache,
      next,
      hideErrorMessage,
    });
  }

  post<T>({
    url,
    headerOptions = {},
    responseModel,
    hideErrorMessage = false,
    data = {},
  }: IPostProps) {
    return this.request<T>({
      url,
      method: HTTP_METHODS_ENUM.POST,
      responseModel,
      headerOptions: {
        [HEADERS_ENUM.contentType]: CONTENT_TYPE_ENUM.applicationJson,
        ...headerOptions,
      },
      data,
      hideErrorMessage,
    });
  }

  patch<T>({ url, headerOptions = {}, data = {} }: IPatchProps) {
    return this.request<T>({
      url,
      method: HTTP_METHODS_ENUM.PATCH,
      headerOptions: {
        [HEADERS_ENUM.contentType]: CONTENT_TYPE_ENUM.applicationJson,
        ...headerOptions,
      },
      data,
    });
  }

  delete<T>({ url, headerOptions = {} }: IDeleteProps) {
    return this.request<T>({
      url,
      method: HTTP_METHODS_ENUM.DELETE,
      headerOptions: {
        [HEADERS_ENUM.contentType]: CONTENT_TYPE_ENUM.applicationJson,
        ...headerOptions,
      },
    });
  }
}

export default HttpService;
