import { forwardRef } from "react";

import { headingVariants } from "@/components/atoms/Heading/constants";
import type { IHeadingProps } from "@/components/atoms/Heading/types";
import cn from "@/helpers/cn";

const Heading = forwardRef<HTMLHeadingElement, IHeadingProps>(
  ({ size, fontWeight, children, className, ...props }, ref) => {
    const Comp = size || "h1";

    return (
      <Comp
        className={cn(headingVariants({ size, fontWeight, className }))}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);

Heading.displayName = "Heading";

export default Heading;
