import localFont from "next/font/local";

export const localeKeyCookie = "NEXT_LOCALE";
export const queryParamRedirectedFromDashboard = "projectId";

export const pageAndLimitDefaultValue = {
  page: 1,
  limit: 12,
  first: 10,
};

export const localInterFont = localFont({
  src: [
    {
      path: "../../../public/fonts/Inter-Bold.woff2",
      weight: "bold",
      style: "normal",
    },
    {
      path: "../../../public/fonts/Inter-Medium.woff2",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../../public/fonts/Inter-Regular.woff2",
      weight: "normal",
      style: "normal",
    },
  ],
  preload: true,
  adjustFontFallback: "Arial",
  display: "swap",
});
