"use client";

import { createContext } from "react";

import type { IAuthContext } from "@/providers/authProvider/types";

const AuthContext = createContext<IAuthContext>({
  state: {
    user: null,
    projectId: null,
    workspaceConfig: null,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

export default AuthContext;
