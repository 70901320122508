"use client";

import { AppLink } from "@/components/atoms/AppLink";
import { Button } from "@/components/atoms/Button";
import { Heading } from "@/components/atoms/Heading";
import { Paragraph } from "@/components/atoms/Paragraph";
import type { INotFoundPage } from "@/components/pages/NotFound/types";
import { useTranslations } from "@/hooks/common/useTranslations";

const NotFoundPage = ({ showBackBtn = true }: INotFoundPage) => {
  const { t } = useTranslations("NotFoundPage");

  return (
    <div className="flex flex-col gap-4 items-center justify-center flex-1">
      <div className="flex flex-col gap-1 items-center justify-center">
        <Heading size="h1" fontWeight="bold" className="text-3xl">
          {t("title")}
        </Heading>
        <Paragraph size="base" fontWeight="medium">
          {t("description")}
        </Paragraph>
      </div>
      {showBackBtn && (
        <AppLink href="/">
          <Button>{t("backHome")}</Button>
        </AppLink>
      )}
    </div>
  );
};

export default NotFoundPage;
