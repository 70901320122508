export enum ENV_CONFIG_ENUM {
  canonicalUrl = "canonicalUrl",
  generalDomain = "generalDomain",
  protocol = "protocol",
  hooryWebsiteToken = "hooryWebsiteToken",
}

export enum HTTP_SERVICE_REQUEST_ENUM {
  accountsFetcher = "accountsFetcher",
  applicationsFetcher = "applicationsFetcher",
}

export enum FilterAndSortOrientations {
  desc = "desc",
  asc = "asc",
}

export enum FilterAndSortColumns {
  createdAt = "createdAt",
  helpfulCount = "helpfulCount",
}

export enum WHITE_LABEL_HEADERS {
  XPartnerDomain = "x-partner-domain",
  XPartnerWorkSpace = "x-partner-workspace",
}
