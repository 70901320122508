"use client";

import type { PropsWithChildren } from "react";
import { useReducer } from "react";

import TranslationsContext from "@/providers/translationsProvider/context";
import translationsReducer from "@/providers/translationsProvider/reducer";
import type { ITranslationsProviderProps } from "@/providers/translationsProvider/types";

const TranslationsProvider = ({
  children,
  translations,
  currentLocale,
}: PropsWithChildren<ITranslationsProviderProps>) => {
  const [state, dispatch] = useReducer(translationsReducer, {
    translations,
    currentLocale,
  });

  const contextValue = { state, dispatch };

  return (
    <TranslationsContext.Provider value={contextValue}>
      {children}
    </TranslationsContext.Provider>
  );
};

export default TranslationsProvider;
